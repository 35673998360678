<template>
  <div>
    <c-search-box @enter="getDeptList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <!-- <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-text
            label="LBLDEPT"
            name="deptNm"
            placeholder='LBLDEPT'
            v-model="searchParam.deptNm">
          </c-text>
        </div> -->
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사용여부 -->
          <c-select
            :comboItems="useFlagItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="useFlag"
            label="LBLUSEFLAG"
            v-model="searchParam.useFlag"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
        <!-- 부서목록 -->
        <c-tree-table
          title="LBL0003215"
          parentProperty="upDeptCd"
          customID="deptCd"
          :columns="gridDept.columns"
          :data="gridDept.data"
          :columnSetting="false"
          :isFullScreen="false"
          :usePaging="false"
          :expandAll="true"
          @rowClick="rowClick"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <!-- 검색 -->
              <c-btn label="LBLSEARCH" icon="search" @btnClicked="getDeptList" />
            </q-btn-group>
          </template>
        </c-tree-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
        <q-form ref="editForm">
          <!-- 부서정보 -->
          <c-card title="LBL0003216" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <!-- 등록 -->
                <c-btn v-if="editable" label="LBLREG" icon="add" @btnClicked="addData" />
                <!-- 삭제 -->
                <c-btn
                  v-if="editable && deleteable"
                  :url="deleteUrl"
                  :isSubmit="isDelete"
                  :param="data"
                  mappingType="DELETE"
                  label="LBLREMOVE"
                  icon="remove"
                  @beforeAction="deleteData"
                  @btnCallback="deleteCallback" />
                <!-- 저장 -->
                <c-btn
                  v-if="editable && saveable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="data"
                  :mappingType="saveType"
                  label="LBLSAVE"
                  icon="save"
                  @beforeAction="saveData"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-6">
                <!-- 부서 -->
                <c-text
                  :required="true"
                  :editable="editable && dataeditable"
                  label='LBLDEPT'
                  name="deptName"
                  type="edit"
                  v-model="data.deptName">
                </c-text>
              </div>
              <div class="col-6">
                <c-plant type="edit" :editable="editable && dataeditable" :required="true" name="plantCd" v-model="data.plantCd" />
              </div>
              <div class="col-6">
                <!-- 부서코드 -->
                <c-text
                  :required="true"
                  :editable="editable && dataeditable && !updateMode"
                  label="LBL0003217"
                  name="deptCd"
                  type="edit"
                  v-model="data.deptCd">
                </c-text>
              </div>
              <div class="col-6">
                <!-- 상위부서 -->
                <c-dept type="edit" :editable="editable && dataeditable" label="LBL0003218" name="upDeptCd" v-model="data.upDeptCd" :isFirstValue="false" />
              </div>
              <div class="col-6">
                <!-- 정렬순서 -->
                <c-text
                  :required="true"
                  :editable="editable && dataeditable"
                  label="LBLSORTORDER"
                  name="sortOrder"
                  type="edit"
                  v-model="data.sortOrder">
                </c-text>
              </div>
              <div class="col-6">
                <!-- 사용여부 -->
                <c-checkbox
                  :editable="editable && dataeditable"
                  :isFlag="true"
                  label="LBLUSEFLAG"
                  name="useFlag"
                  v-model="data.useFlag"
                />
              </div>
            </template>
          </c-card>
        </q-form>
      </div>
    </div>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'dept-manage',
  data() {
    return {
      gridDept: {
        columns: [
          {
            name: 'deptName',
            field: 'deptName',
            // 부서
            label: 'LBLDEPT',
            align: 'left',
            sortable: false,
          },
          {
            name: 'deptCd',
            field: 'deptCd',
            // 부서코드
            label: 'LBL0003217',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'sortOrder',
            field: 'sortOrder',
            // 정렬순서
            label: 'LBLSORTORDER',
            align: 'center',
            style: 'width:60px',
            sortable: false,
          },
          {
            name: 'useFlag',
            field: 'useFlag',
            // 사용여부
            label: 'LBLUSEFLAG',
            align: 'center',
            style: 'width:70px',
            sortable: false,
          },
        ],
        data: [],
      },
      searchParam: {
        plantCd: null,
        deptNm: '',
        useFlag: 'Y',
      },
      data: {
        plantCd: null,
        deptCd: '',
        deptName: '',
        upDeptCd: '',
        sortOrder: '',
        useFlag: 'Y',
      },
      useFlagItems: [
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      listUrl: '',
      detailUrl: '',
      checkUrl: '',
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      saveable: false,
      deleteable: false,
      dataeditable: false,
      editable: true,
      updateMode: false,
      isSave: false,
      isDelete: false,
      saveUrl: transactionConfig.mdm.dept.insert.url,
      saveType: 'POST',
      searchUrl: '',
      selectedDeptCd: '',
      oriDeptCd: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.dataeditable = false;
      // api scope
      this.listUrl = selectConfig.mdm.dept.list.url;
      this.detailUrl = selectConfig.mdm.dept.get.url;
      this.checkUrl = selectConfig.mdm.dept.check.url;
      this.insertUrl = transactionConfig.mdm.dept.insert.url;
      this.updateUrl = transactionConfig.mdm.dept.update.url;
      this.deleteUrl = transactionConfig.mdm.dept.delete.url;
      // list scope
      this.getList();
    },
    rowRemoveSelect() {
      var _table = document.getElementsByClassName('bg-light-blue-1')[0];
      if (_table) {
        _table.classList.remove('bg-light-blue-1');
      }
    },
    getDeptList() {
      this.getList();
      this.reset();
    },
    getList() {
      this.rowNotSelected = true;
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.gridDept.data = _result.data;
      },);
      this.saveable = false;
      this.deleteable = false;
    },
    rowClick(row) {
      // 상세조회
      this.saveable = true;
      this.deleteable = true;
      this.rowNotSelected = false;
      this.updateMode = true;
      this.selectedDeptCd = row.deptCd;
      this.$http.url = this.$format(this.detailUrl, this.selectedDeptCd);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.data = _result.data;
        this.saveUrl = this.updateUrl;
        this.saveType = 'PUT';
        this.dataeditable = true;
        this.oriDeptCd = this.data.deptCd;
      },
      () => {
      });
    },
    addData() {
      this.saveable = true;
      this.deleteable = false;
      this.saveUrl = this.insertUrl;
      this.saveType = 'POST';
      this.updateMode = false;
      this.dataeditable = true;
      this.data = {
        plantCd: null,
        deptCd: '',
        deptName: '',
        upDeptCd: this.selectedDeptCd,
        sortOrder: '',
        useFlag: 'Y',
      };
      this.oriDeptCd = '';
    },
    reset() {
      this.rowRemoveSelect();
      this.saveable = false;
      this.deleteable = false;
      this.updateMode = false;
      this.dataeditable = false;
      this.selectedDeptCd = '';
      this.data = {
        plantCd: null,
        deptCd: '',
        deptName: '',
        upDeptCd: '',
        sortOrder: '',
        useFlag: 'Y',
      };
      this.oriDeptCd = '';
    },
    saveData() {
      this.$refs['editForm'].validate().then(_result => {
        // 중복체크
        if (_result) {
          this.$http.url = this.$format(this.checkUrl, this.data.deptCd);
          this.$http.type = 'GET';
          this.$http.request((_result) => {
            if ((this.updateMode && this.oriDeptCd != this.data.deptCd && _result.data === 0) 
            || (this.updateMode && this.oriDeptCd == this.data.deptCd) 
            || (!this.updateMode && _result.data === 0)) {
              window.getApp.$emit('CONFIRM', {
                title: 'LBLCONFIRM',
                message: 'MSGSAVE', // 저장하시겠습니까?
                // TODO : 필요시 추가하세요.
                type: 'info', // success / info / warning / error
                // 확인 callback 함수
                confirmCallback: () => {
                  this.isSave = !this.isSave;
                },
                // 취소 callback 함수
                cancelCallback: () => {
                },
              });
            } else {
              window.getApp.$emit('ALERT', {
                title: 'LBLGUIDE', // 안내
                message:
                'MSG0001000', // 동일한 부서코드가 존재합니다.
                type: 'warning', // success / info / warning / error
              });
              return;
            }
          },);
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    deleteData() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: 'MSGREMOVE', // 삭제하시겠습니까?,
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.data.deptCd);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            this.isSave = !this.isSave;
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      if (this.saveType == 'PUT') {
        this.getList();
        this.rowClick({ deptCd: result.data.deptCd })
      } else {
        this.getDeptList();
      }
    },
    deleteCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDeptList();
    },
  }
};
</script>
